/**
 * @todo add Image component
 */
import React from 'react'
import { connect } from 'react-redux'
import { graphql } from 'gatsby'
import Layout from '../layouts/Index'
import SEO from '../components/SEO'
import Image from '../components/Images'
import Style from '../styles/pages/team.module.scss'

class Team extends React.Component {
  render() {
    const graphqlRawData = this.props.data;
    const staticPrismicTexts = graphqlRawData.staticText.edges[0].node.data.texts;
    const page = graphqlRawData.team.edges[0].node.data;
    const topRightImage = 'https://wakatoon.twic.pics/8ac7eafd-b9d8-4153-bfdf-f15f8c9627e8_illustration_fleur.png';
    const bottomLeftImage = 'https://wakatoon.twic.pics/b629c683-4b13-47d2-98f0-346e8f80be9d_illustration_oiseau.png';
    
    return (
      <Layout 
        context={this.props.pageContext} 
        pageTitle={this.props.pageContext.title}
        _t={staticPrismicTexts}
      >
        <SEO 
          pageTitle={page.seo__meta_title_.text} 
          pageDescription={page.seo__meta_description_.text}
          context={this.props.pageContext} 
        />
        
        <div className="page">
          <div className={Style.hero}>
            <Image src={page.hero.url} alt={page.image_alt_attribute} />
          </div>
          
          <section style={{background: '#FFFFFF'}}>
            <div className="page-head no-padding no-custom-style">
              <h2>{page.title.text}</h2>
              <div 
                className="page-header-text container-960 waka-padding" 
                dangerouslySetInnerHTML={{__html: page.content.html}}>
              </div>
            </div>

            <div className="container container-960" style={{marginTop: 20}}>
              <div className="illustrations illustrations-topRight">
                <img src={topRightImage} alt="" />
              </div>
              <div className="citation">
                <p>
                  <span className="content">{page.pierrick_says.text}</span>
                  <span className="sign">Pierrick Chabi</span>
                </p>
              </div>
              <div className="illustrations illustrations-bottomLeft">
                <img src={bottomLeftImage} alt="" />
              </div>
            </div>
          </section>

          <section className="regular">
            <div className="container">
              <h2>{page.team_title.text}</h2>
              <div className={`${Style.photos} row`}>
                { page.team_photos.length && page.team_photos.map((item, index) => {
                  return (
                    <div className={`${Style.items} ${Style.col3} col-3`} key={`tem-member-${index}`}>
                      <Image
                        src={item.item.document[0].data.photo.url}
                        alt={item.item.document[0].data.full_name.text}
                      />
                      <span className={Style.fullname}>{item.item.document[0].data.full_name.text}</span>
                      <span className={Style.job}>{item.item.document[0].data.job.text}</span>
                    </div>
                  )
                })}
              </div>
            </div>

            <div className="container">
              <h2>{page.artists_title.text}</h2>
              <div className={`${Style.photos} row`}>
                { page.artists_photos.length && page.artists_photos.map((item, index) => {
                  return (
                    <div className={`${Style.items} ${Style.col3} col-3`} key={`tem-member-${index}`}>
                      <Image
                        src={item.item.document[0].data.photo.url}
                        alt={item.item.document[0].data.full_name.text}
                      />
                      <span className={Style.fullname}>{item.item.document[0].data.full_name.text}</span>
                      <span className={Style.job}>{item.item.document[0].data.job.text}</span>
                    </div>
                  )
                })}
              </div>
            </div>
          </section>
        </div>
      </Layout>
    );
  }
}

export const pageQuery = graphql`
  query TeamPage($locale: String!) {
    team: allPrismicPageTeam(filter: { lang: { eq: $locale} }) {
      edges { ...team }
    }
    staticText: allPrismicGlobalStatictexts(filter: { lang: { eq: $locale} }) {
      edges { ...StaticTexts }
    }
  }
`;

const mapStateToProps = (state) => ({
  ...{ viewportSize: state.viewport.viewportSize }
});

export default connect(mapStateToProps)(Team);